// This variable file will change per group website.
// Together with the scss variables these are really the only two files you should change.

// Enable/Disable API call caching. This should be enabled on the live server, but for development purposes it might be handy to turn it off.
export const apiCaching = false;

// This variable will be added to each page's HTML <title> tag. The existing value which should be unique per page, will be placed in the {pageTitle} tag.
// A | symbol will automatically be inserted between the {pagetitle} tag and the rest of the string.
export const htmlHeadTitle = '{pageTitle} GP Logistics - Global Freight Forwarding Services';

// Menu, used for both the footer and the main menu.
// Sub items are always loaded with AJAX, as such define the container name, and write a function in the onLoad.
// Setting href to null, will create a <div> element rather than an <a>

// For a dropdown menu, we can put an array inside the menu item 'dropdown',
// e.g : 'dropdown' : [{'name' : 'Test', 'href': '/test.html'}]
export const menu = [
  { 'name': 'About Us', 'href': '/about-us.html', 'show': true, 'inGetToKnowUs': true, 'sub': null },
  { 'name': 'Home', 'href': '/', 'show': false, 'inGetToKnowUs': false, 'sub': null },
  { 'name': 'Services', 'href': '/services.html', 'show': true, 'inGetToKnowUs': false, 'sub': { 'class': 'our-services-menu', 'function': 'constructServicesMenu' } },
  { 'name': 'Locations', 'href': '/locations.html', 'show': true, 'inGetToKnowUs': false, 'sub': null },
  { 'name': 'Contacts', 'href': '/contact.html', 'show': true, 'inGetToKnowUs': false, 'sub': null },
  { 'name': 'Stock Control', 'href': 'http://webtracker.graypen.com', 'show': true, 'inGetToKnowUs': false, 'sub': null},
  { 'name': 'Privacy Notice', 'href': '/privacy-notice.html', 'show': false, 'inGetToKnowUs': false, 'sub': null },
  { 'name': 'Cookie Statement', 'href': '/cookie-statement.html', 'show': false, 'inGetToKnowUs': false, 'sub': null },
  { 'name': 'Core Values', 'href': '/about-us.html#core-values', 'show': false, 'inGetToKnowUs': true, 'sub': null },
  { 'name': 'Corporate Responsibility', 'href': '/corporate-responsibilities.html', 'show': false, 'inGetToKnowUs': true, 'sub': null },
  { 'name': 'Trading Terms', 'href': '/bifa.pdf', 'show': false, 'inGetToKnowUs': true, 'sub': null },
];

// The home page has a carousel in the hero. Define the images to show and the text to print underneath.
// If you only specify 1 single entry, there won't be a carousel. Just the one image will be shown.
// In order for the compiler to add the right image`s to the dist folder make sure you import them, and then use the
// imported variable as the image source.
import heroSlide1 from '../img/hero/hero1.jpg';
import heroSlide2 from '../img/hero/hero2.jpg';
import heroSlide3 from '../img/hero/hero3.jpg';
import heroSlide4 from '../img/hero/hero4.jpg';

export const heroCarousel = [
  { 'img': heroSlide4, 'style' : 'object-position: 50% 30%;', 'label': 'Independent freight forwarding shipping solutions' },
  { 'img': heroSlide1, 'style' : 'object-position: 40% 60%;', 'label': 'We can offer you a wide range of services to suit every cargo and budget' },
  { 'img': heroSlide3, 'style' : 'object-position: 50% 70%;', 'label': 'Independent freight forwarding shipping solutions' },
  { 'img': heroSlide2, 'style' : 'object-position: 50% 50%;', 'label': 'We can offer you a wide range of services to suit every cargo and budget' },
];

// The our-group-of-companies html component defines every single logo that is available.
// In this array, specify the logo's alt attribute value that you NOT want visible.
// Leaving this array empty will show ALL logos.
export const removeFromOurGroupOfCompanies = [
  'GP Logistics'
];
export const showExtraButton = {
  'name': 'BLOGS',
  'href': '/blog/what-is-lcl.html',
  'showInMobMenu' : true
};

export const joinTheConvoExtraBox = {
  'mainTitle': 'Our Latest Blogs',
  'title': 'What is LCL (Less Than Container Load) in Shipping?',
  'date': '26the June 2024',
  'description': 'Take a look at our latest blog.',
  'href': '/blog/what-is-lcl.html',
  'button_text':'View Blog',
  'background_color' : '#5e4a12'
};

// API information, make sure the domain ends with a /
export const api = {
  'domain': 'https://api.graypengroup.com/v1/',
  'company': 'GPL',
  'referrer': 'GP Logistics'
};

export const gTagKey = 'G-P8Y4LQ419V';

// Company information which is displayed in the footer.
export const company = {
  'legalName': 'GP Logistics Limited',
  'registeredInCountry': 'England',
  'registrationNumber': '09003781',
  'registeredAddress': 'Registered Office<br>The Bridge, One Graypen Way,<br>Queens Road, Immingham,<br>DN40 1QN, United Kingdom'
};

// Remove Quality Policy for company
export const removeQualityPolicy = true;

export const hideNews = false;

// Locations page settings.
import mapMarkerImage from '../img/map-marker.png';
export const locations = {
  'mapMarkerImage': mapMarkerImage
};

// Company Emblems.
import charteringEmblem from '../img/harvest-chartering1.png';
export const chartering = {
  'companyEmblem': charteringEmblem
};
import bmaEmblem from '../img/bmaEmblem.png';
export const bma = {
  'companyEmblem': bmaEmblem
};
import customsEmblem from '../img/customsEmblem.png';
export const customs = {
  'companyEmblem': customsEmblem
};
import agencyEmblem from '../img/harvest-agency1.png';
export const agency = {
  'companyEmblem': agencyEmblem
};
import steelEmblem from '../img/gpsEmblem.png';
export const steel = {
  'companyEmblem': steelEmblem
};

// How many news items will be rendered as default. The API returns the latest 10, thus you can't pick a number larger than this.
export const maxNewsItems = 5;
export const joinTheConversationBoxColours = [ '#5e4515', '#3a2f16', '#594917'];

// On the Contact page the Directors need to be displayed first, to do this we need to request this team first.
export const companyBoardTeamId = 76;

// The company history timeline is showing important historical events in the company's lifetime.
// Leaving this object empty will remove the entire component from the DOM. Please note that this might conflict with the color scheme on the website
// as we're trying to show 1 coloured component followed by a white component.
export const timelineVisibleItems = 5;
export const timeline = [
  { 'date': '1969/01/01', 'text': '<ul><li>Company formed and established at Immingham (date of incorporation: 23 October 1969)</li></ul>' },
  { 'date': '1971/01/01', 'text': '<ul><li>Company sold to Oughtred & Harrison Ltd.</li></ul>' },
  { 'date': '1984/01/01', 'text': '<ul><li>Exceeded 500 port calls per year.</li></ul>' },
  { 'date': '1986/01/01', 'text': '<ul><li>Exceeded 1000 port calls per year.</li></ul>' },
  { 'date': '1987/01/01', 'text': '<ul><li>Milford Haven Office opened.</li></ul>' },
  { 'date': '1988/01/01', 'text': '<ul><li>Felixstowe Office opened.</li></ul>' },
  { 'date': '1989/01/01', 'text': '<ul><li>Mersey Office opened.</li></ul>' },
  { 'date': '1991/01/01', 'text': '<ul><li>Achieved ISO 9002 quality accreditation (First UK ship agency to do so).</li><li>Moved into own purpose-built head office at Immingham.</li></ul>' },
  { 'date': '1992/01/01', 'text': '<ul><li>Hull Office opened.</li><li>Exceeded 2000 port calls per year.</li></ul>' },
  { 'date': '1993/01/01', 'text': '<ul><li>Fawley and Grangemouth offices opened.</li></ul>' },
  { 'date': '1994/01/01', 'text': '<ul><li>Teesside (Middlesbrough) Office opened.</li></ul>' },
  { 'date': '1995/01/01', 'text': '<ul><li>Sullom Voe, Thames and Nigg Bay offices opened.</li><li>Exceeded 3000 port calls per year.</li></ul>' },
  { 'date': '1996/01/01', 'text': '<ul><li>GP Shipping formed.</li><li>Portland, Goole and Swansea Offices opened.</li><li>Joined Institute of Chartered Shipbrokers.</li></ul>' },
  { 'date': '1997/01/01', 'text': '<ul><li>Plymouth, Avonmouth, Goole, Portland and King’s Lynn Offices opened.</li></ul>' },
  { 'date': '1998/01/01', 'text': '<ul><li>Barry and Whitegate Offices opened.</li></ul>' },
  { 'date': '1999/01/01', 'text': '<ul><li>Joined INTERTANKO.</li><li>Exceeded 4000 port calls per year.</li></ul>' },
  { 'date': '2000/01/01', 'text': '<ul><li>Joined IMPCA.</li><li>Launched websites for Graypen and GP Shipping.</li><li>Glasgow office opened.</li></ul>' },
  { 'date': '2001/01/01', 'text': '<ul><li>Dundee Office opened.</li><li>Hub Agency formed.</li><li>Passed 5000 port calls per year.</li></ul>' },
  { 'date': '2002/01/01', 'text': '<ul><li>Flotta Office opened.</li><li>Joined UPAC.</li></ul>' },
  { 'date': '2003/01/01', 'text': '<ul><li>STS business established.</li><li>Exceeded 6000 port calls per year.</li></ul>' },
  { 'date': '2004/01/01', 'text': '<ul><li>MBO from O&H Group completed.</li></ul>' },
  { 'date': '2006/01/01', 'text': '<ul><li>Established JVC with MariTeam in Rotterdam and Antwerp.</li></ul>' },
  { 'date': '2009/01/01', 'text': '<ul><li>Workington Office opened.</li></ul>' },
  { 'date': '2010/01/01', 'text': '<ul><li>Husbandry Team established.</li><li>Ayr Office opened.</li></ul>' },
  { 'date': '2011/01/01', 'text': '<ul><li>Rotterdam and Newport Office opened.</li><li>Graypen "Online" introduced (M365)</li></ul>' },
  { 'date': '2012/01/01', 'text': '<ul><li>Amsterdam Office opened.</li><li>New HQ Office (The Bridge) built.</li><li>GP Shipping; Bulk Ships Agent of the Year – IBJ Awards.</li><li>MBO Completed.</li></ul>' },
  { 'date': '2013/01/01', 'text': '<ul><li>January - The Bridge opened.</li><li>Antwerp Office opened.</li><li>Passport IT established.</li></ul>' },
  { 'date': '2014/01/01', 'text': '<ul><li>New Workington Office opened.</li><li>John Stronach (Shipbrokers) Limited acquired.</li><li>GP Shipping; Bulk Ships Agent of the Year – IBJ Awards.</li><li>Bennett Marine Associates acquired.</li><li>GP Logistics established.</li><li>7000 port calls per year.</li></ul>' },
  { 'date': '2015/01/01', 'text': '<ul><li>People Development Award - IBJ Awards.</li></ul>' },
  { 'date': '2016/01/01', 'text': '<ul><li>AEO Accreditation received (Authorized Economic Operator).</li><li>MBO Completed.</li></ul>' },
  { 'date': '2017/01/01', 'text': '<ul><li>GP Shipping; Bulk Ships Agent of the Year – IBJ Awards.</li></ul>' },
  { 'date': '2019/01/01', 'text': '<ul><li>Jamar Liner Agencies acquired.</li><li>Harvest Shipping, Chartering & Agency Acquired.</li></ul>' },
  { 'date': '2021/01/01', 'text': '<ul><li>GPL Customs established.</li></ul>' },
  { 'date': '2022/01/01', 'text': '<ul><li>GPL Steel Logistics established.</li><li>GP Shipping; Bulk Ships Agent of the Year – IBJ Awards.</li></ul>' },
  { 'date': '2023/01/01', 'text': '<ul><li>Falmouth Office opened.</li><li>Company remains in private ownership.</li></ul>' },
];


// Pre-Processor for changing text color.
window.servicesPreProcessor = function(service) {
  if (service.name === 'GPL Customs Services') {
    return { 'text': 'style="color:#232076"', 'logo': customsEmblem };
  } else {
    return { 'text': '', 'logo': '' };
  }
};